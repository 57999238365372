import { type FieldMetadata, useInputControl } from '@conform-to/react'
import { format, parse } from 'date-fns'
import React, { useId, useMemo } from 'react'
import { type CaptionLayout, type Matcher } from 'react-day-picker'
import { cn } from '#app/utils/misc.tsx'
import { DatePicker } from './date-picker.tsx'
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx'
import { Input } from './ui/input.tsx'
import { Label } from './ui/label.tsx'
import { Textarea } from './ui/textarea.tsx'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors
  id?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  if (!errorsToRender?.length) {
    return null
  }

  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map(e => (
        <li key={e} className="text-foreground-destructive text-[12px]">
          {e}
        </li>
      ))}
    </ul>
  )
}

export function Field({
  labelProps: { className: labelClassName, ...labelProps },
  inputProps: { className: inputClassName, ...inputProps },
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <Label
        className={cn('font-semibold', labelClassName)}
        htmlFor={id}
        {...labelProps}
      />
      <Input
        className={cn('mt-0', inputClassName)}
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
      />
      <div className="min-h-[16px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export function TextareaField({
  labelProps: { className: labelClassName, ...labelProps },
  textareaProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <Label
        className={cn('font-semibold', labelClassName)}
        htmlFor={id}
        {...labelProps}
      />
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...textareaProps}
      />
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
}: {
  labelProps: JSX.IntrinsicElements['label']
  buttonProps: CheckboxProps & {
    name: string
    form: string
    value?: string
  }
  errors?: ListOfErrors
  className?: string
}) {
  const { key, defaultChecked, ...checkboxProps } = buttonProps
  const fallbackId = useId()
  const checkedValue = buttonProps.value ?? 'on'
  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  })
  const id = buttonProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <div className="flex gap-2">
        <Checkbox
          {...checkboxProps}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          checked={input.value === checkedValue}
          onCheckedChange={state => {
            input.change(state.valueOf() ? checkedValue : '')
            buttonProps.onCheckedChange?.(state)
          }}
          onFocus={event => {
            input.focus()
            buttonProps.onFocus?.(event)
          }}
          onBlur={event => {
            input.blur()
            buttonProps.onBlur?.(event)
          }}
          type="button"
        />
        <label
          htmlFor={id}
          {...labelProps}
          className="text-body-xs text-muted-foreground self-center"
        />
      </div>
      <div className="px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export function DatePickerField({
  meta,
  labelProps: { className: labelClassName, ...labelProps },
  errors,
  className,
  calendarDisabled,
  captionLayout,
  fromYear,
  toYear,
}: {
  meta: FieldMetadata<string | null>
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  errors?: ListOfErrors
  className?: string
  calendarDisabled?: Matcher
  captionLayout?: CaptionLayout
  fromYear?: number
  toYear?: number
}) {
  const control = useInputControl(meta)
  const fallbackId = useId()
  const id = fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  const value = useMemo(() => {
    if (control.value != null) {
      const dateObject = parse(control.value, 'yyyy-MM-dd', new Date())
      return format(dateObject, 'MM.dd.yyyy')
    }
  }, [control.value])

  return (
    <div className={cn('flex flex-col', className)}>
      <Label
        className={cn('mb-1 font-semibold', labelClassName)}
        htmlFor={id}
        {...labelProps}
      />
      <DatePicker
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        value={value}
        onChange={date => control.change(format(date as Date, 'yyyy-MM-dd'))}
        calendarDisabled={calendarDisabled}
        captionLayout={captionLayout}
        fromYear={fromYear}
        toYear={toYear}
      />
      <div className="min-h-[16px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}
