import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'
import * as React from 'react'
import { type CaptionLayout, type Matcher } from 'react-day-picker'
import { cn } from '#app/utils/misc.tsx'
import { Button } from './ui/button.tsx'
import { Calendar } from './ui/calendar.tsx'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover.tsx'

type Props = {
  value?: Date | string
  defaultValue?: Date | string
  onChange?: (date: Date | string | undefined) => void
  disabled?: boolean
  calendarDisabled?: Matcher
  placeholderText?: string
  captionLayout?: CaptionLayout
  fromYear?: number
  toYear?: number
}

export function DatePicker({
  value,
  defaultValue,
  onChange,
  disabled,
  calendarDisabled,
  className,
  style,
  placeholderText,
  captionLayout,
  fromYear,
  toYear,
}: Omit<React.HTMLAttributes<HTMLDivElement>, 'value' | 'defaultValue'> &
  Props) {
  const resolvedDefaultValue = React.useMemo(() => {
    if (defaultValue != null) {
      return typeof defaultValue === 'string'
        ? new Date(defaultValue)
        : defaultValue
    }
    return defaultValue
  }, [defaultValue])
  const [date, setDate] = useControlledValue(value, resolvedDefaultValue)

  const onDateSelect = React.useCallback(
    (date?: Date) => {
      setDate(date ?? resolvedDefaultValue)
      onChange && onChange(date ?? resolvedDefaultValue)
    },
    [resolvedDefaultValue, setDate, onChange],
  )

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id="date"
          variant="outline"
          className={cn(
            'justify-start text-left font-normal',
            !date && 'text-muted-foreground',
            className,
          )}
          disabled={disabled}
          style={style}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date != null ? (
            format(date, 'LLL dd, y')
          ) : (
            <span>{placeholderText || 'Pick a date'}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          initialFocus
          mode="single"
          defaultMonth={date}
          selected={date}
          onSelect={onDateSelect}
          disabled={calendarDisabled}
          captionLayout={captionLayout}
          fromYear={fromYear}
          toYear={toYear}
        />
      </PopoverContent>
    </Popover>
  )
}

function useControlledValue(
  value?: Date | string,
  defaultValue?: Date | string,
): [Date | undefined, (date: Date | undefined) => void] {
  const [date, setDate] = React.useState<Date | undefined>(
    typeof defaultValue === 'string' ? new Date(defaultValue) : defaultValue,
  )
  return React.useMemo(
    () => [
      value != null
        ? typeof value === 'string'
          ? new Date(value)
          : value
        : date,
      setDate,
    ],
    [value, date],
  )
}
